import React from "react";
import { QuestionCard} from "../Components";

export const Pug = (props) => {
    return (
        <QuestionCard {...props} answer='puggalicious' answerSrc='/imgs/pug.jpeg'>
            Some people find it ugly <br />
            But it's <span className='hint-emphasis'>rolls</span> make you squeal <br />
            This <span className='hint-emphasis'>jumper</span> makes you cuddly <br />
            before all is revealed
        </QuestionCard>);
}