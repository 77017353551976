import React from "react";
import { QuestionCard} from "../Components";

export const IceCream = (props) => {
    return (
        <QuestionCard {...props} answer='pidapippo' answerSrc='/imgs/ice_cream.jpeg'>
            A little bit <span className='hint-emphasis'>cold</span><br />
            Especially in winter <br />
            A <span className='hint-emphasis'>treat</span> after dinner <br />
            With a delicious shiver
        </QuestionCard>);
}