import React from "react";
import { QuestionCard } from '../Components'

export const Corgi = (props) => {
    return (
        <div>
            <QuestionCard {...props} answer='corgmaster' answerSrc='/imgs/corgi.jpeg'>
                A <span className='hint-emphasis'>furry</span> friend<br />
                <span className='hint-emphasis'>Short</span> and stout<br />
                <span className='hint-emphasis'>Pinned</span> to your shirt<br />
                with an adorable snout
            </QuestionCard>
        </div>)
}