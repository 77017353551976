import React from "react";
import {Link} from "react-router-dom";
import {NextPage} from "./NextPage";

export const AnsweredContent = (props) => {
    const { src, next } = props;

    return (<div>
        <img data-src={src} style={{maxHeight: '750px'}} alt='' data-uk-img='' />
        <div style={{ paddingTop: '20px' }}><Link to={next} component={NextPage} /></div>
    </div>)
}