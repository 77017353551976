import React from "react";
import { Link } from "react-router-dom";
import {NextPage} from "../Components";

export const Start = (props) =>
{
    return (
        <div className='uk-position-center  question-card'>
            <div className='uk-container uk-container-small uk-card uk-card-default uk-card-body'>
                <p>The answers to the riddles are scattered through the house. Solve the riddle and find the answer to proceed!</p>
                <Link to='/1' component={NextPage} />
            </div>
        </div>)
}

