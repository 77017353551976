import React from "react";

export const Wymm = (props) =>
{
    return (
        <div className='uk-position-center question-card'>
            <div className='uk-container uk-container-small uk-card uk-card-default uk-card-body'>
                <img data-src='/imgs/together.jpeg' style={{maxHeight: '700px'}} alt="" data-uk-img='' />
                <h2 className='wymm-title' style={{  }}>Will you Marry Me?</h2>
            </div>
        </div>)
}

/* <p><button className='uk-button uk-button-large btn-gradient' style={{color: 'white'}} onClick={celebrate()}>Yes</button></p> */
