import React from "react";

export const NextPage = React.forwardRef((props, ref) =>
{
   let {children} = props;
   if(!children)
   {
      children = <span>Next!</span>
   }

   return (<button ref={ref} onClick={props.navigate} disabled={props.disabled} className='uk-button uk-button-primary'>{children}</button>)
});