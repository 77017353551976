import React from 'react';
import {
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import 'uikit/dist/css/uikit.min.css'
import './App.css';
import { Corgi, Start, Pug, HotChocolate, IceCream, Wymm } from "./Pages"
import {NextPage} from "./Components";
import {useTransition, animated} from "react-spring";

function App() {
  const location = useLocation();
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  })
  return transitions.map(({item, props, key}) => (
      <animated.div key={key} style={props} className="App">
          <Switch location={item}>
            <Route path="/5" component={Wymm} />
            <Route path="/4" component={Pug} />
            <Route path="/3" component={HotChocolate} />
            <Route path="/2" component={Corgi} />
            <Route path="/1" component={IceCream} />
            <Route path="/start" component={Start} />
            <Route path="/" component={Welcome} />
          </Switch>
      </animated.div>
  ));
}

export default App;

const Welcome = () => (
  <div className='uk-position-center question-card'>
    <div className='uk-container uk-container-small uk-card uk-card-default uk-card-body'>
      <h1>Hi Mich!</h1>
      <p>I made a game! Do you want to play?</p>
      <Link to='/start' component={NextPage}>Yes!</Link>
      <Link to='/start' component={NextPage} disabled>No</Link>
    </div>
  </div>
)

/* <div className='dev-bar uk-position-bottom-center'><Link to='/'>Reset</Link></div> */