import React from "react";
import { QuestionCard} from "../Components";

export const HotChocolate = (props) => {
    return (
            <QuestionCard {...props} answer='morkchoc' answerSrc='/imgs/mork.jpeg'>
                Warm and sweet <br />
                Our <span className='hint-emphasis'>first treat</span> together <br />
                A good place to <span className='hint-emphasis'>meet</span> <br />
                Forever my treasure
            </QuestionCard>)
}



/*
Delicious and sweet <br />
A good place to meet <br />
From our beginnings <br />
We've done the opposite of thinning
 */