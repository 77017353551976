import React, {useEffect, useState} from "react";
import {QuestionContent} from "./QuestionContent";
import {AnsweredContent} from "./AnsweredContent";
import { useLocation } from 'react-router-dom'
import { useTransition, animated } from "react-spring";

export const QuestionCard = (props) => {
    const location = useLocation();
    const nextPage = `/${Number(location.pathname[1]) + 1}`;

    const [answered, setAnswered] = useState(false);
    const [givenAnswer, setAnswer] = useState('');

    useEffect(() => {
        setAnswered(givenAnswer.toLowerCase() === props.answer.toLowerCase());
    }, [props.answer, givenAnswer, setAnswered])

    const transitions = useTransition(answered, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });

    const { answerSrc, children, answer } = props;

    return transitions.map(({ item, key, props}) => {
        return (item
            ? <animated.div className='uk-position-center question-card' style={props}>
                <div
                    className='uk-container uk-container-xsmall uk-card uk-card-default uk-card-body'>
                    <AnsweredContent next={nextPage} src={answerSrc}/>
                </div>
            </animated.div>
            : <animated.div className='uk-position-center question-card' style={props}>
                <div
                    className='uk-container uk-container-xsmall uk-card uk-card-default uk-card-body'>
                    <QuestionContent children={children} answer={answer} setAnswer={setAnswer}/>
                </div>
            </animated.div>)
    })
}
/*
{
 */